<template>
  <SkillCard></SkillCard>
</template>

<script>
import SkillCard from '@/components/SkillCard.vue'
export default {
  name: "SkillsView",
  components: {
    SkillCard
    }
}
</script>

<style scoped>

</style>>

